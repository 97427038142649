<template>
  <q-page class="row justify-center items-center bg-primary">
    <div class="column q-pa-lg">
      <div class="row">
        <transition appear enter-active-class="animated animate__slideInDown">
          <q-card class="loginContainer">
            <q-card-section class="text-center">
              <img src="@/assets/images/logo_retina.png" height="100" />
            </q-card-section>
            <q-form
              @submit.prevent.stop="onSubmit"
              @reset.prevent.stop="onReset"
            >
              <q-card-section class="column items-start q-col-gutter-md">
                <FormInput
                  label="Account Code"
                  icon="store"
                  tabindex="1"
                  v-model="retailerCode"
                  :rules="[VALIDATION.required]"
                />

                <FormInput
                  label="Email"
                  icon="email"
                  tabindex="2"
                  v-model="email"
                  :rules="[VALIDATION.required]"
                />

                <FormInput
                  label="Password"
                  icon="lock"
                  tabindex="3"
                  v-model="password"
                  :rules="[VALIDATION.required]"
                  password
                />
              </q-card-section>

              <q-card-actions class="q-mt-sm q-pa-md">
                <Button
                  type="submit"
                  label="Login"
                  variant="primary"
                  size="lg"
                  tabindex="4"
                  :loading="isSubmitting"
                  :disabled="isSubmitting"
                  fullWidth
                />
              </q-card-actions>
            </q-form>
          </q-card>
        </transition>
      </div>
    </div>
  </q-page>
</template>

<script>
import { useQuasar } from 'quasar'
import { inject, ref, onBeforeMount, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

import { FormInput } from '@/components/inputs'
import Button from '@/components/Button'

import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  components: { FormInput, Button },
  setup() {
    const $q = useQuasar()
    const store = inject('store')
    const router = useRouter()
    const { showToast } = Toast()
    const { data, error, loading, post } = fetchData()

    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const retailerCode = ref(null)
    const email = ref(null)
    const password = ref(null)

    const onSubmit = () => {
      if (!email.value || !password.value) {
        showToast('Email and Password is required', 'danger')
      } else {
        const formData = {
          account: {
            account_code: retailerCode.value,
            email: email.value,
            password: password.value
          }
        }

        post('auth/login', formData)
      }
    }

    const onReset = () => {
      retailerCode.value = null
      email.value = null
      password.value = null
    }

    const onSuccess = data => {
      const route = router.resolve({
        name: 'dashboard'
      })
      showToast('Login success', 'success')

      localStorage.setItem('token', data)
      localStorage.removeItem('defaultID')
      window.location.assign(route.href)
    }

    onBeforeMount(() => {
      isLoading.value = true
      $q.loading.show()

      if (store.state.token) {
        const route = router.resolve({
          name: 'dashboard'
        })
        window.location.assign(route.href)
      }
    })

    onMounted(() => {
      isLoading.value = false
      $q.loading.hide()
    })

    watch([data, error, loading], () => {
      isSubmitting.value = loading.value

      if (!loading.value) {
        if (error?.value) {
          showToast(
            error.value.errors
              ? error.value.errors[0].msg
              : error.value.message,
            'danger'
          )
        } else if (data?.value) {
          onSuccess(data?.value?.access_token)
        }
      }
    })

    return {
      store,
      retailerCode,
      email,
      password,
      isLoading,
      isSubmitting,
      VALIDATION,
      onSubmit,
      onReset
    }
  }
}
</script>

<style lang="scss">
.loginContainer {
  max-width: 350px;
  padding: 20px;
  border-radius: 15px;
}
</style>
